<template>
    <div>
        <app-view-table :isDropdownMenu="cDropDownMenu" :isTopBar="false"  :title="trans('applicants',5)" :loading="cLoadList" :filters.sync="filters" :items="items" :canUserEdit="user_role < 3" v-on:onRowSelected="getProfile" :fields="fields" :isSearch="true" :isSelectable=" filters.list_type != 3" :isRowHover="filters.list_type != 3" :isFilterOptions="true" v-bind:class="{ 'table-selectable': filters.list_type != 3 }">            
            <template v-slot:menu-items="cell">
                <b-dropdown-item @click="rejectApplicant(cell.data.item)" v-if="cell.data.item.id_status_const_type != 1">{{trans('c-withdraw',175)}}</b-dropdown-item>
                <b-dropdown-item @click="deleteApplicant(cell.data.item)" v-if="cell.data.item.id_status_const_type != 1">{{trans('decline',175)}}</b-dropdown-item>
            </template>

            <template v-slot:buttons>
                <app-button type="primary" v-if="filters.list_type != 3" @click="mApplicantAdd.show = true">{{trans('fl-add-button',174)}}</app-button>
            </template>
        </app-view-table>

        <people-applicants-add v-if="mApplicantAdd.show" :show.sync="mApplicantAdd.show" :args.sync="mApplicantAdd.args"/>  
    </div>
</template>
<script>
 
// #ID _03-02.00 - PDH 2022-01-26

import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';
import PeopleApplicantsAdd from './PeopleApplicantsAdd.vue'
import UserSettings from '@core/scripts/UserSettings';

  export default {
    components: {
      PeopleApplicantsAdd
    },

    props: ["loadList", "listType", "search", "total", 'refresh'],

    mounted() {
        this.filters.sorting.field_name = null;
        this.filters.filtering.functions.values = [];
        this.filters.filtering.types.values = [];
        this.filters.filtering.statuses.values = [];
        this.filters.filtering.locations.values = [];
        if(this.$route.params.status != null){
            this.changeFilters(this.$route.params.status);
        }else{
            this.onCreateThis();
        }
    },

    computed: {
        visibleFields() {
            return this.fields.filter(field => field.visible)
        },

        cLoadList: {
            get() { return this.loadList },
            set(value) { this.$emit('update:loadList', value) }
        },

        cTotal: {
            get() { return this.total },
            set(value) {this.$emit('update:total', value) }
        },
        cDropDownMenu: {
             get() { return this.listType != 4 &&  this.listType != 6},
        }
    },

    data() {
        return {
            mApplicantAdd: { show: false, args: {is_student_office: null, is_borrow_office: null}, result: 0, return: null },
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                filtering: {                        
                        functions: { type: 'list', changed: 0, values: [], function: "getFunctions", parameters: null, widthClass:'app-width-min-350'},
                        types: {type: 'list', changed: 0, values: [], function: "getApplicantTypes", parameters: null, widthClass:'app-width-min-250'},
                        statuses: { type: 'list', changed: 0, values: [], function: "getApplicantStatuses", parameters: null, widthClass:'app-width-min-250'},
                        locations: { type: 'list',  changed: 0, values: [], function: "getLocations", parameters: null, widthClass:'app-width-min-250'}
                },
                page: 1,
                per_page: 10,
                search: "",
                changed: 0,
                list_type: 1,
            },
            fields: [
                { key: "person_name", label: this.trans('full-name',3), thStyle: { "min-width": "90px", width: "100%" }, visible: true, filtering: false},
                { key: "function_name", label: this.trans('function',3), thStyle: { "min-width": "200px", "max-width": "200px" }, visible: true, filtering: true, filtering_name: "functions"},
                { key: "type_name", label: this.trans('agreement',3), thStyle: { "min-width": "200px", "max-width": "200px" }, visible: true, filtering: true, filtering_name: "types"},                
                { key: "location_name", label: this.trans('branch',182), thStyle: { "min-width": "200px", "max-width": "200px" }, visible: true, filtering: true, filtering_name: "locations"},  
                { key: "status_name", label: this.trans('status',3), thStyle: { "min-width": "200px", "max-width": "200px" }, visible: true, filtering: true, filtering_name: "statuses"},              
                { key: "phone", label: this.trans('phone-number',182), thStyle: { "min-width": "200px", "max-width": "200px" }, visible: false, filtering: false},
                { key: "email", label: this.trans('email',182), thStyle: { "min-width": "200px", "max-width": "200px" }, visible: false, filtering: false},

            ],
            items: [],
            alertClass: new AppAlerts(),
            userSettings: new UserSettings(),
            columns_array:['person_name','function_name','type_name','location_name', 'status_name', 'phone', 'email'],
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,

        };
    },

    methods: {
        getList() {
           this.cLoadList = true;
           axios
                .post("people/applicants/getList", {
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {         
                    this.filters.page = res.data.page;
                    this.items = res.data.items;   
                    this.mApplicantAdd.args.is_student_office = res.data.is_student_office;  
                    this.mApplicantAdd.args.is_borrow_office = res.data.is_borrow_office;                 
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.cLoadList = false;
                    this.cTotal = this.items.total;
                });
        },

        getProfile: function(selectedRows) {
            if(selectedRows.length != 0 && selectedRows[0].id_status_const_type != 1){
                this.$router.push({ name: "people-person-profile", params: { id_person_data_main: selectedRows[0].id_person_data_main } });
            }
        },      

        rejectApplicant: function(data) {
            if(data.inbetween_name != null){
                var personName = data.first_name + ' ' + data.inbetween_name + ' ' + data.last_name;
            }else{
                var personName = data.first_name + ' ' + data.last_name;
            }
            if(data.id_status_const_type < 1 || data.id_status_const_type >= 5 ){
                this.alertClass.openAlertInfo(this.trans('applicant-no-suitable-status-error',180)).then(res => {
                }); 
            }else{
                this.alertClass.openAlertConfirmation(this.trans('person-withdraw-application-confirmation',177,{personName:personName})).then(res => {
                    if(res == true){
                        this.cLoadList = true;
                        axios
                            .post("people/applicants/reject", {
                                filters: JSON.stringify(this.filters),
                                id_person_data_main: data.id_person_data_main,
                            })
                            .then((res) => {
                                this.filters.page = res.data.page
                                this.items = res.data.items;
                            })
                            .catch((error) => {
                                console.error(`error during request: ${error}`);
                            })
                            .finally(() => {
                                this.cLoadList = false;
                                this.cTotal = this.items.total;
                            });;
                    }     
                }); 
            }
        },
        deleteApplicant: function( data) {
            if(data.inbetween_name != null){
                var personName = data.first_name + ' ' + data.inbetween_name + ' ' + data.last_name;
            }else{
                var personName = data.first_name + ' ' + data.last_name;
            }
            if(data.id_status_const_type < 1 || data.id_status_const_type >= 5 ){
                this.alertClass.openAlertInfo(this.trans('applicant-no-suitable-status-error',180)).then(res => {
                }); 
            }else{
                this.alertClass.openAlertConfirmation(this.trans('reject-applicant-confirmation',177,{personName:personName})).then(res => {
                    if(res == true){
                        this.cLoadList = true;
                        axios
                            .post("people/applicants/delete", {
                                filters: JSON.stringify(this.filters),
                                id_person_data_main: data.id_person_data_main,
                            })
                            .then((res) => {
                                this.filters.page = res.data.page
                                this.items = res.data.items;
                            })
                            .catch((error) => {
                                console.error(`error during request: ${error}`);
                            }).finally(() => {
                                this.cLoadList = false;
                                this.cTotal = this.items.total;
                            });;
                    }     
                }); 
            }
        },

        changeFilters(status){
            this.filters.filtering.statuses.values = [status];
            this.getList();
        },

        listTypeChanged(first_time = false) {
            if(!first_time){
                this.filters.page = 1;
                this.filters.sorting.field_name = null;
                this.filters.filtering.functions.values = [];
                this.filters.filtering.types.values = [];
                this.filters.filtering.statuses.values = [];
                this.filters.filtering.locations.values = [];
            }
            
            if(this.filters.list_type == 4 || this.filters.list_type == 6){
                this.fields[1].visible = true;
                this.fields[2].visible = true;
                this.fields[3].visible = true;
                this.fields[4].visible = false;
                this.fields[5].visible = false;
                this.fields[6].visible = false;
            }else{
                this.fields[1].visible = true;
                this.fields[2].visible = true;
                this.fields[3].visible = true;
                this.fields[4].visible = true;
                this.fields[5].visible = false;
                this.fields[6].visible = false;
            }

        },

        saveSettings() {
            this.userSettings.saveSettings(this.filters, 1);
        },

        async loadSettings() {
           var getData = JSON.parse(localStorage.getItem('userSettings'));

            if(getData == undefined || getData.applicantList == undefined){
                await this.userSettings.saveSettings(this.filters, 1);
                getData = JSON.parse(localStorage.getItem('userSettings'));
            }

            var sFilters = getData.applicantList
            if(sFilters.list_type == 3 && this.listType != 3){
                this.filters.list_type = this.listType;
            }else{
                this.filters.list_type = sFilters.list_type;
            }
            this.filters.filtering = sFilters.filtering;
            if(this.columns_array.includes(sFilters.sorting.field_name)){
                this.filters.sorting = sFilters.sorting;
            }           
            this.filters.per_page = sFilters.per_page != undefined ? sFilters.per_page : 10; 
            this.listTypeChanged(true);      
        },


        onCreateThis() { 
       
            this.loadSettings();

            this.getList();
            this.fields.filter(field => field.visible);
            this.saveSettings();

            this.$root.$on('locationsChanged', () => {
        
                if (!this.cLoadList) {
                    this.getList();
                } 
            });
        
            this.$watch('listType', function(newVal, oldVal) {
                if (!this.cLoadList) {
                    this.filters.list_type = this.listType;
                    this.listTypeChanged();
                    this.saveSettings()
                    this.getList();
                } 
            });

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.cLoadList) {
                    this.getList();
                    this.saveSettings()
                } 
            });

            this.$watch('filters.changed', function(newVal, oldVal) {
                if (!this.cLoadList) {
                    this.getList();
                    this.saveSettings()
                } 
            });

            this.$watch('filters.filtering.functions.changed', function(newVal, oldVal) {
                if (!this.cLoadList) {
                    this.getList();
                    this.saveSettings()
                } 
            });

            this.$watch('filters.filtering.statuses.changed', function(newVal, oldVal) {
                if (!this.cLoadList) {
                    this.getList();
                    this.saveSettings()
                } 
            });

            this.$watch('filters.filtering.locations.changed', function(newVal, oldVal) {
                if (!this.cLoadList) {
                    this.getList();
                    this.saveSettings()
                } 
            });

            this.$watch('filters.filtering.types.changed', function(newVal, oldVal) {
                if (!this.cLoadList) {
                    this.getList();
                    this.saveSettings()
                } 
            });

            this.$watch('search', function(newVal, oldVal) {
                if (!this.cLoadList) {
                    this.filters.search = this.search;
                    this.filters.page = 1;
                    this.getList();
                } 
            });

           
            this.$watch('items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.cLoadList) {
                    this.getList();
                }   
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.cLoadList) {
                    this.getList();
                }   
            });

            this.$watch('refresh', function(newVal, oldVal) {
                if (!this.cLoadList) {
                    this.getList();
                }   
            });


        },

    },
  };
</script>
<style scoped>
.app-local-sticky {
    position: absolute !important;
    right: 0;
    top: auto;
}

</style>
