<template>
    <b-card>
        <b-overlay :show="loading" :opacity="0.6">
            <b-row class="app-card-header" >
                    
                    <b-col start>
                        <div class="app-card-tittle app-local-title-height">
                            <div> {{trans('applicants',5)}} <span v-if="!loading">({{ total }})</span></div>
                        </div>
                    </b-col>


                    <b-col class="app-input-applicant-filter-width" >
                        <b-form-input type="text" v-model="search" :lazy="true" class="form-control app-input" :class="{'app-input-filled': search.length > 0}" :placeholder="trans('search',3)" size="sm"/>
                    </b-col>
                    <b-col class="app-input-employee-filter-width pl-0" end >
                        <app-select v-model="type" reduceValue customBody :clearable="false" :selectOnTab="true" :options="user_status_list" :selectable="(item) => item.value > 0">
                            <template v-slot:option="option">
                            <hr v-if="option.data.value < 0" class="app-search-modal-separator">    
                            <span  v-else>{{ option.data.name }}</span>
                            </template>
                        </app-select>  
                    </b-col>

                    <b-col cols="auto" end class="d-flex align-items-center pl-0">
                        <app-refresh :refresh.sync="refresh" :loading="loading"  />
                    </b-col>
                </b-row>
            <template #overlay>
                <div></div>
            </template>
        </b-overlay>

        <people-applicants-list-component v-if="type != 3" :loadList.sync="loading" :search.sync="search" :total.sync="total" :listType.sync="type" :refresh.sync="refresh"/>
        <people-applicants-recrui-now-list-component v-else :loadList.sync="loading" :search.sync="search" :total.sync="total"  :refresh.sync="refresh"/>
    </b-card>  
</template>
<script>
 

import PeopleApplicantsListComponent from './PeopleApplicantsListComponent.vue'
import PeopleApplicantsRecruiNowListComponent from './PeopleApplicantsRecruiNowListComponent.vue'


  export default {
    components: {
      PeopleApplicantsListComponent,
      PeopleApplicantsRecruiNowListComponent
    },

    created(){
        this.loadSettings();
    },

    data() {
        return {
           loading: false,
            type: 1,
            search: '',
            total: 0,
            offer_date: null,
            offer_week: null,
            date_type: 1,            
            refresh:0,
             user_status_list: [
                { value: 1, name: this.trans('s-applicants-per-location',176) },
                { value: 2, name: this.trans('s-my-applicants',176) },
                { value: 3, name: this.trans('rn-new',176) },                
                { value: -1, name: ""},
                { value: 5, name: this.trans('s-all',176) },
                { value: -2, name: ""},   
                { value: 4, name: this.trans('s-withdrawn',176) },
                { value: 6, name: this.trans('s-turned-down',176) }, 
            ],
        };
    },

    methods: {

        async loadSettings() {
           var getData = JSON.parse(localStorage.getItem('userSettings'));
            if(getData != undefined && getData.applicantList != undefined){
                var sFilters = getData.applicantList
                this.type = sFilters.list_type; 
            }
    
        }
    },
  };
</script>
<style scoped>
.app-local-sticky {
    position: absolute !important;
    right: 0;
    top: auto;
}

</style>
