<template>
    <b-row>
            <b-col cols="4" class="pr-5px">                
                <app-select v-model="cPrefix" :clearable="false" :selectOnTab="true" :options="internationals" :disable="disabled" @input="changePrefix"/>
            </b-col>
            <b-col cols="8" class="pl-5px">
                <app-input v-model="cNumber" :disabled="disabled" :mask="phoneMask" :validatorRules="isRequired == true ? phoneRulesRequired : phoneRules" :dontShowError="true" @blur="setError()"/>
            </b-col>
            <validation-provider v-slot="{ errors}" ref="provider" :rules="isRequired == true ? phoneRulesRequired : phoneRules" :name="this.trans('phone-number',182) ">
                <input type="hidden" name="date" v-model="cNumber">
                <small v-if="errors.length > 0 && errors[0] != ''" class="ml-3 text-error app-input-error-ellips" data-toggle="tooltip" :title="errors[0]">{{ errors[0] }}</small>        
            </validation-provider>
        </b-row> 
</template>
<script>

import internationals_json from "@/assets/json/internationals.json";

export default {
    
    props: {
            prefix: {
                type: String,
                default: "+31"
            },

            phone: {
                type: String
            },

            isRequired: { type: Boolean, default: true},
            disabled: {type: Boolean, default: false}
    },
  
    computed: {
        
        cPrefix: {
            get() { return this.prefix },
            set(value) {this.$emit('update:prefix', value) }
        },

        cNumber: {
            
            get() { return this.phone },
            set(value) {this.$emit('update:phone', value) }
        }    
    },

    data() {
        return {
            internationals: internationals_json,
            phoneMask: "##########",
            phoneRules: "min:9",
            phoneRulesRequired: "required|min:9"
        };
    },

    methods: {

        changePrefix(value) {

            if (value == '+31') {
                this.phoneMask = "##########";
                this.phoneRules = "min:9";
                this.phoneRulesRequired = "required|min:9";
            } else {
                this.phoneMask = "####################";
                this.phoneRules = "min:5";
                this.phoneRulesRequired = "required|min:5";
            }
        },

        setError(){
            if(!this.cNumber || this.cNumber.length == 0 && this.isRequired == true){
                this.$refs.provider.setErrors([ this.trans('phone_number_required',180) ]);
            }
            this.$refs.provider.validate()
        }
      
    }

};
</script>

<style>

</style>