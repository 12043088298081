<template>
    <div>
        <app-view-table :isTopBar="false"  :title="trans('applicants',5)" :loading="cLoadList" :filters.sync="filters" :items="items" :fields="fields" :isSearch="true" :isSelectable=" filters.list_type != 3" :isRowHover="filters.list_type != 3" :isFilterOptions="true" :customColumns="customColumns">
            
            <template v-slot:menu-items="cell">
                <b-dropdown-item @click="registerApplicant(cell.data.item)" v-if="cell.data.item.id_status_const_type == 1">Registreren</b-dropdown-item>
                <b-dropdown-item @click="openCv(cell.data.item)" v-if="cell.data.item.cv == 1">Open CV</b-dropdown-item>
            </template>

            <template v-slot:person_name="cell">
                {{cell.data.item.last_name + ', ' + cell.data.item.first_name + (cell.data.item.inbetween_name !== null ? ' ' + cell.data.item.inbetween_name : '')}}
            </template>

            <template v-slot:cv="cell">
                    <span v-if="cell.data.item.cv == 1">
                        <b-icon icon="check-2"></b-icon>
                    </span>
            </template>

        </app-view-table>


        <people-applicants-register v-if="mApplicantRegister.show" :show.sync="mApplicantRegister.show" :args="mApplicantRegister.args"/>   
    </div>
</template>
<script>
 
// #ID _03-02.00 - PDH 2022-01-26

import axios from "@axios";
import PeopleApplicantsRegister from './PeopleApplicantsRegister.vue'
import UserSettings from '@core/scripts/UserSettings';

  export default {
    components: {
      PeopleApplicantsRegister
    },

    props: ["loadList", "search", "total", 'refresh'],

    mounted() {
        this.filters.sorting.field_name = null;
        this.onCreateThis();
    },

    computed: {
        visibleFields() {
            return this.fields.filter(field => field.visible)
        },

        cLoadList: {
            get() { return this.loadList },
            set(value) { this.$emit('update:loadList', value) }
        },

        cTotal: {
            get() { return this.total },
            set(value) {this.$emit('update:total', value) }
        },
    },

    data() {
        return {
            mApplicantRegister: { show: false, args: {id_person_data_main: null,
                                                        first_name:null,
                                                        last_name:null,
                                                        inbetween_name:null,
                                                        email:null,
                                                        phone_prefix:null,
                                                        phone_number:null,
                                                        is_student_office: null
                                                    }, result: 0, return: null },
            customColumns: ["person_name","cv"],
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },

                filtering: {                        
                        functions: { type: 'list', changed: 0, values: [], function: "getFunctions", parameters: null, widthClass:'app-width-min-350'},
                        types: {type: 'list', changed: 0, values: [], function: "getApplicantTypes", parameters: null, widthClass:'app-width-min-250'},
                        statuses: { type: 'list', changed: 0, values: [], function: "getApplicantStatuses", parameters: null, widthClass:'app-width-min-250'},
                        locations: { type: 'list',  changed: 0, values: [], function: "getLocations", parameters: null, widthClass:'app-width-min-250'}
                },
                page: 1,
                per_page: 10,
                search: "",
                changed: 0,
                list_type: 3
            },

            fields: [
                { key: "person_name", label: this.trans('full-name',3), thStyle: { "min-width": "90px", width: "100%" }, visible: true, filtering: false},
                { key: "phone", label: this.trans('phone-number',182), thStyle: { "min-width": "200px", "max-width": "200px" }, visible: true, filtering: false},
                { key: "entry_date", label: this.trans('entry-date-on',182), thStyle: { "min-width": "150px", "max-width": "150px" }, visible: true, filtering: false},
                { key: "cv", label: this.trans('cv',182), thStyle: { "min-width": "75px", "max-width": "75px" }, visible: true, filtering: false},

            ],
            items: [],
            userSettings: new UserSettings(),
            columns_array:['person_name','phone','entry_date','cv'],
        };
    },

    methods: {
        getList() {
           this.cLoadList = true;
           axios
                .post("people/applicants/getList", {
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {                    
                    this.filters.page = res.data.page;
                    this.items = res.data.items;       
                    this.mApplicantRegister.args.is_student_office = res.data.is_student_office;                
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.cLoadList = false;
                    this.cTotal = this.items.total;
                });
        },    

        registerApplicant: function(data) {
            this.mApplicantRegister.args.id_recruitnow_data_main = data.id_recruitnow_data_main;
            this.mApplicantRegister.args.first_name = data.first_name;
            this.mApplicantRegister.args.inbetween_name = data.inbetween_name;
            this.mApplicantRegister.args.last_name = data.last_name;
            this.mApplicantRegister.args.email = data.email;
            this.mApplicantRegister.args.cv = data.cv;
            this.mApplicantRegister.args.phone_number = data.phone_number;
            this.mApplicantRegister.args.phone_prefix = data.phone_prefix != null ? data.phone_prefix : '+31';
            this.mApplicantRegister.show = true;
        },



        saveSettings() {
            this.userSettings.saveSettings(this.filters, 1);
        },

        async loadSettings() {
           var getData = JSON.parse(localStorage.getItem('userSettings'));

            if(getData == undefined || getData.applicantList == undefined){
                await this.userSettings.saveSettings(this.filters, 1);
                getData = JSON.parse(localStorage.getItem('userSettings'));
            }

            var sFilters = getData.applicantList

            //this.filters.list_type = sFilters.list_type;
            this.filters.filtering = sFilters.filtering;
            if(this.columns_array.includes(sFilters.sorting.field_name)){
                this.filters.sorting = sFilters.sorting;
            }
           this.filters.per_page = sFilters.per_page != undefined ? sFilters.per_page : 10;  
  
        },

        openCv(data) {
            this.cLoadList = true;
            axios
                .post("people/applicants/dowloadCV", {
                    id_recruitnow: data.id_recruitnow
                })
                .then((res) => {
                    const byteCharacters = Buffer.from(res.data.content, 'base64');
                    const url = window.URL.createObjectURL(new Blob([byteCharacters], {type : res.data.content_type}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.target= "_blank";
                     res.data.content_type == 'application/pdf'
                                     ? link.setAttribute('open', res.data.name):link.setAttribute('download', res.data.name); 
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    console.error(error);
                    this.alertClass.openAlertWarning(this.trans('file-not-found',180)).then(res => {})
                })
                .finally(() => {
                   this.cLoadList = false;
                   this.cTotal = this.items.total;
                });

        },

        onCreateThis() { 
       
            this.loadSettings();

            this.getList();
            this.fields.filter(field => field.visible)
            this.saveSettings();

            this.$root.$on('locationsChanged', () => {
        
                if (!this.cLoadList) {
                    this.getList();
                } 
            });
    

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.cLoadList) {
                    this.getList();
                    this.saveSettings()
                } 
            });

            this.$watch('filters.changed', function(newVal, oldVal) {
                if (!this.cLoadList) {
                    this.getList();
                    this.saveSettings()
                } 
            });

            this.$watch('filters.filtering.functions.changed', function(newVal, oldVal) {
                if (!this.cLoadList) {
                    this.getList();
                    this.saveSettings()
                } 
            });

            this.$watch('filters.filtering.statuses.changed', function(newVal, oldVal) {
                if (!this.cLoadList) {
                    this.getList();
                    this.saveSettings()
                } 
            });

            this.$watch('filters.filtering.locations.changed', function(newVal, oldVal) {
                if (!this.cLoadList) {
                    this.getList();
                    this.saveSettings()
                } 
            });

            this.$watch('filters.filtering.types.changed', function(newVal, oldVal) {
                if (!this.cLoadList) {
                    this.getList();
                    this.saveSettings()
                } 
            });

            this.$watch('search', function(newVal, oldVal) {
                if (!this.cLoadList) {
                    this.filters.search = this.search;
                    this.filters.page = 1;
                    this.getList();
                } 
            });

           
            this.$watch('items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.cLoadList) {
                    this.getList();
                }   
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.cLoadList) {
                    this.getList();
                }   
            });

            this.$watch('refresh', function(newVal, oldVal) {
                if (!this.cLoadList) {
                    this.getList();
                }   
            });
        },

    },
  };
</script>
<style scoped>
.app-local-sticky {
    position: absolute !important;
    right: 0;
    top: auto;
}

</style>
