<template> 
    
    <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('fl-header',176)">
        
        <ValidationObserver ref="form" >
            <b-form @submit.prevent="onSubmit">    

                <app-divider-modal :text="trans('applicant',176)" />

                <app-row-left-label :label="trans('f-gender',184)">
                    <app-radio-group v-model="applicant_data.sex" :options="sex" validatorName="Sex" validatorRules="required" :validatorCustomMessage="{ required: trans('gender-required',177)}" />
                </app-row-left-label>

                <app-row-left-label :label="trans('first-name',182)">
                    <app-input v-model="applicant_data.first_name" validatorName="Voornaam" validatorRules="required" mask="lettersE1-20"/>
                </app-row-left-label>    
                
                <app-row-left-label :slots="[4,8]" :label="trans('last-name',182)">
                    <template v-slot:col-1>
                        <app-input v-model="applicant_data.inbetween_name" :placeholder="trans('inbetween-name',182)" mask="lettersE1-10"/>
                    </template>
                     <template v-slot:col-2>
                        <app-input v-model="applicant_data.last_name" :placeholder="trans('last-name',182)" validatorName="Achternaam" validatorRules="required" mask="lettersE1-30" />
                    </template>
                </app-row-left-label>

                <app-row-left-label :label="trans('contract-type',182)">
                    <app-radio-group v-model="applicant_data.applicant_type" :options="applicant_type" validatorName="Soort contract" validatorRules="required" :validatorCustomMessage="{ required: trans('fl-type-of-contract-error',177) }" :stacked="args.is_student_office"/>
                </app-row-left-label>
                
                <app-row-left-label :label="trans('function',3)">
                    <app-select v-model="applicant_data.function" :clearable="true" :type="function_type" validatorName="Functie" validatorRules="required" :disable="applicant_data.applicant_type == null" :refreshIndex="refresh_functions"/>
                </app-row-left-label>
                
                <app-divider-modal :text="trans('contact-details',182)" :isLine="true"/>
                
                <app-row-left-label :label="trans('phone-number',182)">
                    <app-phone :prefix.sync="applicant_data.phone_prefix" :phone.sync="applicant_data.phone_number" />
                </app-row-left-label>
                 
                <app-row-left-label :label="trans('email',182)">
                    <app-email :email.sync="applicant_data.email" validatorName="Email" validatorRules="required" type="person" />
                </app-row-left-label>
                
                <app-divider-modal :text="trans('address-details',3)" :isLine="true"/>

                <app-address ref="address_control" :address.sync="applicant_data.address" required/>
                
                <app-divider-modal :text="trans('happynurse-data',3)" :isLine="true"/>
               
                <app-row-left-label :label="trans('branch',182)">
                    <app-select v-model="applicant_data.location" type="getUserLocations" :disable="false" validatorName="Vestiging" validatorRules="required"/>
                </app-row-left-label>

                <app-row-left-label :label="trans('recruiter',182)">
                    <app-select v-model="applicant_data.recruiter" :refreshIndex.sync="refresh_user" :args.sync="users_args" type="getUsersFromLocation" :disable="users_args.id_office_data_location < 1" validatorName="Recruiter" validatorRules="required" />
                </app-row-left-label>

                <app-row-left-label :label="trans('pl-submit-check',176)">
                    <app-check-box id="ch_email" v-model="applicant_data.send_mail" class="custom-control-primary" :disabled="applicant_data.applicant_type == 4" value="1" unchecked-value="0" size="lg" />
                </app-row-left-label>
               
            </b-form>
        </ValidationObserver>
        
        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button><br>
            <app-button type="primary" :loading="loading" @click="applicationAdd">{{trans('add',175)}}</app-button>
        </template>
    </b-modal>
    
</template>

<script>

// #ID _03-02.01 - DDA 2022-01-14

import axios from "@axios";
import {ValidationObserver} from "vee-validate";
import AppAddress from '@core/components/Address/AppAddress.vue';
import AppPhone from '@core/components/AppPhone.vue';
import AppEmail from '@core/components/AppEmail.vue';

export default {
    components: {        
        ValidationObserver,
        AppPhone,
        AppEmail,
        AppAddress,
        AppPhone
    },

    props: ["show", "args"],

    created(){
        

        this.user_data = JSON.parse(localStorage.getItem('user'));
        var user_locations = JSON.parse(localStorage.getItem("USER_LOCATIONS"));

        if(this.user_data != null){
            this.applicant_data.recruiter = {name: this.user_data.user_name, value: this.user_data.id}
        }

        if(user_locations.length == 1){
            this.applicant_data.location = {name: user_locations[0].text, value: user_locations[0].value}
            this.users_args.id_office_data_location = this.applicant_data.location.value;
        }

        if(this.args.is_student_office){
            this.applicant_type.push({value: 3, text: this.trans('fl-student', 176), disabled: true})
        }

        if(this.args.is_borrow_office){
            this.applicant_type.push({value: 4, text: 'Doorleen', disabled: true})
        }

    },

    watch: {
        
        "applicant_data.location": {
            handler: function(val) {
                
                this.users_args.id_office_data_location = this.applicant_data.location.value;

                if(this.applicant_data.recruiter.value != this.user_data.id){
                    this.applicant_data.recruiter.name = "";
                    this.applicant_data.recruiter.value = null;
                    this.applicant_data.recruiter = null;
                }

                this.refresh_user++;

                if(this.args.is_student_office){
                    var student_index = this.applicant_type.findIndex((item) => item.value == 3);
                    if(val.student_recruitment == '0' && this.applicant_data.applicant_type == 3){
                        this.applicant_type[student_index].disabled = true;
                        this.applicant_data.applicant_type = null;
                        this.applicant_data.function = null;
                    } else if(val.student_recruitment == '0'){
                        this.applicant_type[student_index].disabled = true;
                    } else if(val.student_recruitment == '1'){
                        this.applicant_type[student_index].disabled = false;
                    }
                }

                if(this.args.is_borrow_office){
                    var borrow_index = this.applicant_type.findIndex((item) => item.value == 4)
                    if(val.borrow == '0' && this.applicant_data.applicant_type == 4){
                        this.applicant_type[borrow_index].disabled = true;
                        this.applicant_data.applicant_type = null;
                        this.applicant_data.function = null;
                    }
                    else if(val.borrow == '1'){
                        this.applicant_type[borrow_index].disabled = false;
                    } else if(val.borrow == '0'){
                        this.applicant_type[borrow_index].disabled = true;
                    }
                }
            },
        },

        "applicant_data.applicant_type": {
            handler: function(val){
                if(val == 3){
                    this.function_type = 'getFunctionsStudent';
                    this.refresh_functions++;
                    this.applicant_data.function = null;
                } else if(val == 2) {
                    this.function_type = 'getFunctionsZZP';
                    this.refresh_functions++;
                    this.applicant_data.function = null;
                } else if(val == null){

                } else{
                    this.function_type = 'getFunctions';
                    this.refresh_functions++;
                    this.applicant_data.function = null;
                } 

                if(val == 4){
                    this.applicant_data.send_mail = "0";
                } else {
                    this.applicant_data.send_mail = "1";
                }
            }
        }
    },

    computed: {
        computedShow: {
        get() { return this.show },
        set() { this.$emit('update:show', false) }, 
        }
    },

    data() {
        return {
            loading: false,

            applicant_data: {
                        sex: null,
                        first_name: "",
                        inbetween_name:"",
                        last_name: "",
                        function: null,
                        applicant_type: null,
                        phone_prefix: '+31',
                        phone_number: '',
                        email: "",
                        
                        address: {
                                type: 1,
                                post_code: "", 
                                house_number: "", 
                                house_number_additional:"",
                                city:"",
                                street:"",
                                latitude: null,
                                longitude: null,
                                country: null
                            },

                        send_mail: "1",
                        id_office_data_location: null,
                        country: null,
                        location: null,
                        recruiter: null,
                    },

            refresh_user: 1,
            users_args: {id_office_data_location: null},

            user_data: null,

            sex: [
                { value: 1, text: this.trans('gender-male',184) },
                { value: 2, text: this.trans('gender-female',184) },
            ],

            applicant_type: [
                {value: 1, text: this.trans('fl-uzk',176)},
                {value: 2, text: this.trans('fl-zzp',176)}
            ],
            function_type: 'getFunctions',
            refresh_functions: 0 
        };
    },

    methods: {

        applicationAdd() {
           this.$refs.form.validate().then((result) => {
                if (!result) {            
                    return false;
                }else{
                    if(!this.$refs.address_control.address.street && this.applicant_data.address.post_code.length > 0){
                        return false;
                    }else{
                        this.loading = true;

                        axios
                            .post("people/applicants/add", {
                                applicant_data: JSON.stringify(this.applicant_data),
                            })
                            .then((res) => {
                                
                                if(res.data == -1){                            
                                    this.loading = false;
                                    return false;
                                }

                                this.$emit("update:show", false);
                                this.loading = false;
                                this.$router.push({ name: "people-person-profile", params: { id_person_data_main: res.data } });
                            })
                            .catch((error) => {
                                console.error(`error during request: ${error}`);
                            });
                    }
                }
                
            })
        },

        closeModal() {
            this.$emit("update:show", false);
        },

    },
};
</script>
