<template> 
    <b-modal ref="modal-add" size="md" v-model="computedShow" no-close-on-backdrop :title="trans('rn-header',176)">
        <b-overlay :show="loading_modal" :opacity="0.5" class="app-modal-content-overlay-fill">
        <ValidationObserver ref="form" >
            <b-form @submit.prevent="onSubmit">
                <app-divider-modal :text="trans('applicant',176)"/>

                <app-row-left-label :label="trans('f-gender',184)">
                    <app-radio-group v-model="applicant_data.sex" :options="sex" validatorName="Sex" validatorRules="required" :validatorCustomMessage="{ required: trans('gender-required',177) }" />
                </app-row-left-label>

                <app-row-left-label :label="trans('first-name',182)">
                    <app-input v-model="applicant_data.first_name" validatorName="Voornaam" validatorRules="required" mask="letters-20"/>
                </app-row-left-label>  

                <app-row-left-label :slots="[4,8]" :label="trans('last-name',182)">
                    <template v-slot:col-1>
                        <app-input v-model="applicant_data.inbetween_name" :placeholder="trans('inbetween-name',182)" mask="lettersE1-10"/>
                    </template>
                    <template v-slot:col-2>
                        <app-input v-model="applicant_data.last_name" placeholder="trans('last-name',182)" validatorName="Achternaam" validatorRules="required" mask="lettersE1-30" />
                    </template>
                </app-row-left-label>

                <app-row-left-label :label="trans('contract-type',182)">
                    <app-radio-group v-model="applicant_data.applicant_type" :options="applicant_type" validatorName="Soort contract" validatorRules="required" :validatorCustomMessage="{ required: trans('fl-type-of-contract-error',177) }" :stacked="args.is_student_office"/>
                </app-row-left-label>
                
                 <app-row-left-label :label="trans('function',3)">
                    <app-select v-model="applicant_data.function" :clearable="true" :type="function_type" validatorName="Functie" validatorRules="required" :disable="applicant_data.applicant_type == null" :refreshIndex="refresh_functions"/>
                    <small v-if="!loading_modal" class="text-muted ml-1">RecruitNow: {{applicant_data.functions}}</small>
                </app-row-left-label>
                
                <app-divider-modal :text="trans('contact-details',182)" :isLine="true"/>

                <app-row-left-label :label="trans('phone-number',182)">
                    <app-phone :prefix.sync="applicant_data.phone_prefix" :phone.sync="applicant_data.phone_number" />
                </app-row-left-label>
                
                <app-row-left-label :label="trans('email',182)">
                    <app-email :email.sync="applicant_data.email" type="person" :validatorRules="applicant_data.send_mail == '1' ? 'required' : ''" />
                </app-row-left-label>

                <app-divider-modal :text="trans('address-details',3)" :isLine="true"/>

                <app-address ref="address_control" v-if="!loading_modal" :required="true" :address.sync="applicant_data.address"/>

                <app-divider-modal :text="trans('happynurse-data',3)" :isLine="true"/>
            
                <app-row-left-label :label="trans('branch',182)">
                    <app-select v-model="applicant_data.location" type="getUserLocations" :disable="false" validatorName="Vestiging" validatorRules="required"/>
                    <small v-if="!loading_modal" class="text-muted ml-1">RecruitNow: {{applicant_data.name_department}}</small>
                </app-row-left-label>

                <app-row-left-label :label="trans('recruiter',182)">
                    <app-select v-model="applicant_data.recruiter" :refreshIndex.sync="refresh_user" :args.sync="users_args" type="getUsersFromLocation" :disable="users_args.id_office_data_location < 1" validatorName="Recruiter" validatorRules="required" />
                    <small v-if="!loading_modal" class="text-muted ml-1">RecruitNow: {{applicant_data.name_recruter}}</small>
                </app-row-left-label>

                <app-row-left-label :label="trans('pl-submit-check',176)">
                    <app-check-box id="ch_email" v-model="applicant_data.send_mail" class="custom-control-primary" value="1" unchecked-value="0" size="lg" />
                </app-row-left-label>
            </b-form>
        </ValidationObserver>
        </b-overlay>
        <template #modal-footer>
            <app-button type="cancel" @click="closeModal">{{trans('cancel',175)}}</app-button><br>
            <app-button type="primary" :loading="loading" @click="applicationAdd">{{trans('add',175)}}</app-button>
        </template>
    </b-modal>
    
</template>

<script>
import axios from "@axios";
import { ValidationObserver } from "vee-validate";
import AppAddress from '@core/components/Address/AppAddress.vue';
import AppPhone from '@core/components/AppPhone.vue';
import AppEmail from '@core/components/AppEmail.vue';

export default {
    components: {
        AppAddress,
        AppPhone,
        AppEmail,
        ValidationObserver,
    },

    props: ["show", "args"],

    created(){
        
        this.getData();

        this.user_data = JSON.parse(localStorage.getItem('user'));
        var user_locations = JSON.parse(localStorage.getItem("USER_LOCATIONS"));

        if(this.user_data != null){
            this.applicant_data.recruiter = {name: this.user_data.user_name, value: this.user_data.id}
        }

        if(user_locations.length == 1){
            this.applicant_data.location = {name: user_locations[0].text, value: user_locations[0].value}
            this.users_args.id_office_data_location = this.applicant_data.location.value;
        }

        if(this.args.is_student_office){
            this.applicant_type.push({value: 3, text: this.trans('fl-student', 176), disabled: true})
        }
    },

    watch: {
        "applicant_data.location": {
            handler: function(val) {
                this.users_args.id_office_data_location = this.applicant_data.location.value;

                if(this.applicant_data.recruiter.value != this.user_data.id){
                    this.applicant_data.recruiter.name = "";
                    this.applicant_data.recruiter.value = null;
                    this.applicant_data.recruiter = null;
                }

                this.refresh_user++;

                if(this.args.is_student_office){
                    if(val.student_recruitment == '0' && this.applicant_data.applicant_type == 3){
                        this.applicant_type[2].disabled = true;
                        this.applicant_data.applicant_type = null;
                        this.applicant_data.function = null;
                    } else if(val.student_recruitment == '0'){
                        this.applicant_type[2].disabled = true;
                    } else if(val.student_recruitment == '1'){
                        this.applicant_type[2].disabled = false;
                    }
                }
            },
        },

        "applicant_data.applicant_type": {
            handler: function(val){
                if(val == 3){
                    this.function_type = 'getFunctionsStudent';
                    this.refresh_functions++;
                    this.applicant_data.function = null;
                } else if(val == 2) {
                    this.function_type = 'getFunctionsZZP';
                    this.refresh_functions++;
                } else{
                    this.function_type = 'getFunctions';
                    this.refresh_functions++;
                }
            }
        }
        
    },

    computed: {
        computedShow: {
        get() { return this.show },
        set() { this.$emit('update:show', false) }, 
        }
    },

    data() {
        return {
            loading: false,
            loading_modal: true,
            applicant_data:{
                sex: null,
                name_recruter: '',
                name_department:'',
                functions:'',
                applicant_type: null,
                first_name: this.args.first_name,
                inbetween_name:this.args.inbetween_name,
                last_name: this.args.last_name,
                phone_prefix: this.args.phone_prefix,
                phone_number: this.args.phone_number,
                function: null,
                email: this.args.email,
                cv: this.args.cv,
                address: {
                    type: 1,
                    post_code: "", 
                    house_number: "", 
                    house_number_additional:"",
                    city:"",
                    street:"",
                    country: null,
                    latitude: null,
                    longitude: null
                },
                send_mail: "1",
                location: null,
                recruiter: null,
            },
            refresh_user: 1,
            users_args: {id_office_data_location: null},
            user_data: null,

            sex: [
                { value: 1, text: this.trans('gender-male',184) },
                { value: 2, text: this.trans('gender-female',184) },
            ],

            applicant_type: [
                {value: 1, text: this.trans('fl-uzk',176)},
                {value: 2, text: this.trans('fl-zzp',176)}
            ],
            function_type: 'getFunctions',
            refresh_functions: 0 
        };
    },

    methods: {

        getData: function() {
            this.loading_modal = true;
            axios
                .post("people/applicants/getImportedData", {
                    id_recruitnow_data_main: this.args.id_recruitnow_data_main,
                })
                .then((res) => {
                    this.applicant_data.first_name = res.data.first_name;
                    this.applicant_data.inbetween_name = res.data.inbetween_name;
                    this.applicant_data.last_name = res.data.last_name;
                    this.applicant_data.email = res.data.email;
                    this.applicant_data.address.address_type = res.data.address_type?  res.data.address_type : 1;
                    this.applicant_data.address.post_code = res.data.post_code ? res.data.post_code : '';
                    this.applicant_data.address.house_number = res.data.house_number ? res.data.house_number : '';
                    this.applicant_data.address.house_number_additional = res.data.number_additional? res.data.number_additional : '';
                    this.applicant_data.address.city = res.data.city ? res.data.city : '';
                    this.applicant_data.address.street = res.data.street ? res.data.street : '';
                    this.applicant_data.sex = parseInt(res.data.sex);
                    this.applicant_data.name_department = res.data.name_department;
                    this.applicant_data.name_recruter = res.data.name_recruter;
                    this.applicant_data.functions = res.data.functions;
                    if(res.data.user_id != null){
                        this.applicant_data.recruiter.name = res.data.user_name;
                        this.applicant_data.recruiter.value = res.data.user_id;
                    }

                    if(res.data.office_id != null){
                        this.applicant_data.location = {name: null, value: null};
                        this.applicant_data.location.name = res.data.office_name;
                        this.applicant_data.location.value = res.data.office_id;
                    }
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                      this.loading_modal = false;
                });
        },

        applicationAdd() {
            this.$refs.form.validate().then((result) => {
                if (!result || this.loadingEmailCheck == 1 || this.loadingAddressCheck == 1) {
                    return false;
                }else{
                    if(!this.$refs.address_control.address.street){
                        return false;
                    }else{
                    
                        this.loading = true;

                        axios
                            .post("people/applicants/register", {
                                applicant_data: JSON.stringify(this.applicant_data),
                                id_recruitnow_data_main: this.args.id_recruitnow_data_main
                            })
                            .then((res) => {
                                
                                if(res.data == -1){
                                    this.$refs.form.setErrors({
                                        email: ['Emailadres is al bezet.']
                                    });
                                    this.loading = false;
                                    return false;
                                }
                                this.$emit("update:show", false);
                                this.loading = false;
                                this.$router.push({ name: "people-person-profile", params: { id_person_data_main: res.data } });
                            })
                            .catch((error) => {
                                console.error(`error during request: ${error}`);
                            });
                    }
                        
                }
            })
                
        },

        closeModal() {
            this.$emit("update:show", false);
        },
        
    },
};
</script>

<style scoped>

 
</style>
